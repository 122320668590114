import { useEffect, useState } from "react";
import { useAppDispatch } from "../../Redux/store";
import { setIdentity, setToken } from "../../Redux/login";
import Buttons from "../04-Micros/Buttons";
import { useNavigate } from "react-router-dom";
import { auth, getIdentity } from "../../Api";
import { toast } from "react-toastify";
import LoginInput from "../04-Micros/LoginInput";
import ChangelogModal from "../03-Partials/ChangelogModal";
import { changelog } from "../../Json";

const Login = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<boolean>(false);
    const [changelogState, setChangelogState] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            setError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, password]);

    useEffect(() => {
        toast.info("Pro přihlášení prosím použijte údaje z Hockey Logic.", {
            toastId: "useHLCredentials",
        });
    }, []);

    const handleLogin = () => {
        auth(email, password)
            .then((res) => {
                dispatch(setToken(res.data));
                getIdentity(res.data.access_token)
                    .then((res) => {
                        console.log(res);
                        setEmail("");
                        setPassword("");
                        dispatch(setIdentity(res.data));
                    })
                    .catch((err) => console.log("Get identity err:", err));
                navigate("/matches");
            })
            .catch((err) => {
                setError(true);
                toast.error("Nesprávné přihlašovací údaje.", {
                    toastId: "wrongCredentials",
                });
                console.log("Auth err:", err);
            });
    };

    const handleChangelog = () => {
        setChangelogState((prevState) => !prevState);
    };

    return (
        <>
            <main className="Login">
                <div className="Login-content">
                    <div className="Login-box">
                        <img
                            className="Login-logo"
                            src={require("../../Assets/coach-logo.png")}
                            alt="logo"
                        />
                        <div className="Login-form">
                            <LoginInput
                                title="E-mail"
                                type="email"
                                value={email}
                                error={error}
                                setValue={setEmail}
                                handleEnter={handleLogin}
                            />
                            <LoginInput
                                title="Heslo"
                                type="password"
                                value={password}
                                error={error}
                                setValue={setPassword}
                                handleEnter={handleLogin}
                            />
                            <Buttons
                                title="Přihlásit se"
                                color="loginBlue"
                                type="contained"
                                padding={14}
                                fontSize={14}
                                onClick={handleLogin}
                            />
                        </div>
                        <div className="Login-version">
                            <p>Verze aplikace 1.1</p>
                            <p
                                className="Login-version--changelog"
                                onClick={handleChangelog}
                            >
                                Changelog
                            </p>
                        </div>
                    </div>
                    <a href="https://hockeylogic.cz" className="Login-otherApp">
                        <img
                            src={require("../../Assets/logic-logo.png")}
                            alt="logo"
                        />
                        <p className="Login-otherApp--text">
                            Přejít do aplikace
                        </p>
                    </a>
                    <div className="Login-copyright">
                        <p>
                            © 2024{" "}
                            <a href="https://www.bpa.cz">
                                BPA sport marketing a.s.
                            </a>{" "}
                            & <a href="http://esportsmedia.cz">eSports.cz</a>
                        </p>
                    </div>
                </div>
            </main>
            <ChangelogModal
                open={changelogState}
                onClose={handleChangelog}
                changelog={changelog}
            />
        </>
    );
};

export default Login;
